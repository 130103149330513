.yp-breadcrumb {
  @extend .breadcrumb;

  &__item {
    + .yp-breadcrumb__item {

      padding-left: 0.5rem;

      &::before {
        color: inherit;
        content: '/';
        display: inline-block; // Suppress underlining of the separator in modern browsers
        padding-right: 0.5rem;
      }
    }

    &--current {
      color: inherit;

      .yp-breadcrumb__link {
        cursor: default;
      }
    }
  }
}
