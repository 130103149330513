.yp-social-media {
  &__list {
    list-style: none;
    padding-left: 0;
  }

  &__icon--facebook {
    @include icon('before', 'fab', $fa-var-facebook);
  }

  &__icon--twitter {
    @include icon('before', 'fab', $fa-var-twitter);
  }

  &__icon--linkedin {
    @include icon('before', 'fab', $fa-var-linkedin);
  }

  &__icon--pinterest {
    @include icon('before', 'fab', $fa-var-pinterest);
  }

  &__icon--instagram {
    @include icon('before', 'fab', $fa-var-instagram);
  }

  &__icon--youtube {
    @include icon('before', 'fab', $fa-var-youtube);
  }

  &__icon--tiktok {
    @include icon('before', 'fab', $fa-var-tiktok);
  }

  &__icon--copy-link {
    @include icon('before', 'far', $fa-var-link);
  }
}
