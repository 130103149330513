.yp-rating {
  &__items {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    display: inline-block;

    &::before {
      @extend .far;
      content: fa-content($fa-var-star);
    }

    &--filled::before {
      @extend .fas;
    }
  }
}
