@import '~bootstrap/scss/mixins/transition';
@import '~bootstrap/scss/mixins/border-radius';
@import '~bootstrap/scss/mixins/box-shadow';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/modal';

.yp-modal {
  @extend .modal;

  &__dialog {
    @extend .modal-dialog;

    overflow: hidden;

    &--centered {
      @extend .modal-dialog-centered;
    }
  }

  &__content {
    @extend .modal-content;
  }

  &__header {
    @extend .modal-header;

    .yp-modal__close {
      &--left {
        margin: -1rem;
      }

      &--center {
        margin: -1rem auto;
      }
    }

    .yp-modal__close-icon::before {
      @extend .far;
      content: fa-content($fa-var-times);
    }
  }

  &__title {
    @extend .modal-title;
  }

  &__body {
    @extend .modal-body;
  }

  &--fade {
    @extend .fade;
  }

  &__footer {
    @extend .modal-footer;
  }

}
