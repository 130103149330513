.yp-form {
  &__group {
    @extend .form-group;

    &-check {
      @extend .form-check;
    }
  }

  &__label {
    &-check {
      @extend .form-check-label;
    }

    &--hidden {
      @include sr-only;
      @include sr-only-focusable;
    }
  }

  &__control {
    @extend .form-control;
  }

  &__check-input {
    @extend .form-check-input;
  }

  &__alert {
    &.yp-alert {
      &--error {
        @extend .invalid-feedback;
      }
    }
  }
}
