/* Font awesome */
$fa-font-path: '/fonts/fa';
@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
@import '~@fortawesome/fontawesome-pro/scss/brands';
@import '~@fortawesome/fontawesome-pro/scss/light';
@import '~@fortawesome/fontawesome-pro/scss/regular';
@import '~@fortawesome/fontawesome-pro/scss/solid';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/_mixins';

@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';

@import '_mixins/mixins';
@import '_components/components';
@import '_utilities/utilities';
@import '_containers/containers';
