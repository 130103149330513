@mixin icon($position: 'before', $font-family: false, $icon: false) {
  &::#{$position} {
    @if $font-family {
      @extend .#{$font-family} !optional;
    }
    @if $icon {
      content: fa-content($icon);
    }
    // Include any extra rules supplied for the pseudo-element
    @content;
  }
}
