.sc-content-wrapper {
  font-size: 12px;

  .sc-content-properties {
    background-color: #fafafa !important;
    border: 1px solid #888 !important;
    border-radius: 5px !important;
    box-shadow: 0 0 5px #dadada !important;
    cursor: pointer !important;
    float: right !important;
    opacity: 0.95 !important;
    position: absolute !important;
    z-index: 999999;

    table {
      margin: 1px !important;
    }

    th {
      background-color: #565656 !important;
      color: #fff !important;
      font-size: 12px !important;
      padding: 2px !important;
      text-align: left !important;
      width: 50px !important;
    }

    td {
      background-color: #dadada !important;
      color: #000 !important;
      font-size: 12px !important;
      font-weight: bold !important;
      padding: 2px !important;
      width: 100px !important;
    }
  }
}
