.yp-collaborator,
.yp-collaborators-list {
  &__img {
    .yp-collaborator__icon::before,
    .yp-collaborators-list__icon::before {
      @extend .fas;
      content: fa-content($fa-var-user);
    }
  }
}
